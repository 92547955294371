import request from '@/utils/request';

// 发起GET请求
export const GetHello = () => {
    return request({
        url: '/hello',
        method: 'GET'
    })
}

export const GetSoftList = (params) => {
    return request({
        url: '/soft/list/all',
        params: params,
        method: 'GET'
    })
}

export const GetSoftType = (params) => {
    return request({
        url: '/soft/list/type',
        params: params,
        method: 'GET'
    })
}

export const GetSoftSelect = (params) => {
    return request({
        url: '/soft/list/select',
        params: params,
        method: 'GET'
    })
}

export const GetSoftSearch = (params) => {
    return request({
        url: '/soft/search',
        params: params,
        method: 'GET'
    })
}

export const SoftDown = (data) => {
    return request({
        url: '/soft/down',
        data: data,
        method: 'POST'
    })
}