<template>
    <div class="soft_class_list">
        <div v-for="(item, index) in list" :key="index" class="soft_classes">
            <div class="soft_class" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave()">
                <img :src="item.IconUrl" class="soft_class_icon_url" />
                <div class="soft_class_info">
                    <div class="soft_class_info_name">{{ item.Name }}</div>
                    <div class="soft_class_info_version" :style="{ 'width': indexNum === index ? 0 : 106 + 'px' }">
                        Version {{ item.Version }}</div>
                </div>
                <div :class="{ 'necess_soft_info_down': indexNum === index }" v-show="indexNum === index"
                    @click="downUrl(item.Name)">立即下载</div>
            </div>
        </div>
    </div>
</template>

<script>
import { SoftDown } from "@/api/hello"
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            indexNum: ""
        }
    },
    methods: {
        mouseEnter(index) {
            this.indexNum = index
        },
        mouseLeave() {
            this.indexNum = -1
        },
        downUrl(softName) {
            const formData = new FormData()
            formData.append("name", softName)
            SoftDown(formData).then(res => {
                console.log(res.data)
                if (res.data.code === 200) {
                    location.href = res.data.data.Url
                    this.$message({
                        message: '下载成功',
                        type: 'success'
                    });
                } else {
                    this.isSearch = false
                    // this.status = "none"
                    console.log('error')
                    this.$message({
                        message: '下载异常!!',
                        type: 'error'
                    });
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.soft_class_list {
    display: flex;
    flex-wrap: wrap;
    // width: 1140px;
    // height: 348px;

    .soft_classes {
        margin-right: 10px;
        margin-top: 16px;
    }

    .soft_class {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        display: flex;
        padding: 16px 20px;
        align-items: center;
        justify-content: flex-start;
        height: 100px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        background: #FFF;

        .soft_class_icon_url {
            width: 60px;
            height: 60px;
            margin-right: 8px;
        }

        .soft_class_info {
            display: flex;
            flex-direction: column;

            .soft_class_info_name {
                margin-bottom: 10px;
                height: 20px;
                width: 106px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 106px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 10px;
            }

            .soft_class_info_version {
                color: #999;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                height: 17px;
                width: 106px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 106px;
            }
        }

        .necess_soft_info_down {
            // display: block;
            position: absolute;
            box-sizing: border-box;
            left: 86px;
            bottom: 20px;
            color: #FFF;
            border-radius: 5px;
            background: #2049EE;
            color: #FFF;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            padding: 5px 16px;
            width: 89px;
            height: 30px;
            cursor: pointer;
        }


        // .soft_class_info {
        //     display: flex;
        //     flex-direction: column;

        //     .soft_class_info_name {
        //         // display: flex;
        //         // align-items: center;
        //         margin-bottom: 10px;
        //         height: 20px;
        //         width: 106px;
        //         white-space: nowrap;
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         max-width: 106px;
        //     }

        //     .soft_class_info_version {
        //         color: #999;
        //         font-family: "Microsoft YaHei UI";
        //         font-size: 12px;
        //         font-style: normal;
        //         font-weight: 400;
        //         height: 17px;
        //         width: 106px;
        //         white-space: nowrap;
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         max-width: 106px;
        //     }
        // }
    }

    .soft_classes:nth-child(5n) {
        margin-right: 0px !important;
    }

    .soft_class:hover {
        background-color: #EAF4FF;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
    }
}
</style>