<template>
    <div class="necess_container">
        <div v-for="(item, index) in list" :key="index" class="necess_cards">
            <div class="necess_card" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave()">
                <img :src="item.IconUrl" class="necess_icon_url">
                <div class="necess_soft_info" :style="selectNumber(index)">
                    <div class="necess_soft_info_txt">{{ item.Name }}</div>
                    <div class="necess_soft_info_txt version">{{ item.Description }}</div>
                </div>
                <div class="necess_soft_info_down" v-show="indexNum === index" @click="downUrl(item.Name)">一键安装</div>
            </div>
        </div>
    </div>
</template>

<script>
import { SoftDown } from "@/api/hello"
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            divWidth: 264,
            indexNum: ""
        }
    },
    methods: {
        selectNumber(index) {
            if (this.indexNum === index) { return { width: this.divWidth + 'px' } } else {
                return { width: 264 + 'px' }
            }
        },
        mouseEnter(index) {
            this.indexNum = index
            this.divWidth = 180
        },
        mouseLeave() {
            this.indexNum = -1
            this.divWidth = 264
        },
        downUrl(softName) {
            const formData = new FormData()
            formData.append("name", softName)
            SoftDown(formData).then(res => {
                console.log(res.data)
                if (res.data.code === 200) {
                    location.href = res.data.data.Url
                    this.$message({
                        message: '下载成功',
                        type: 'success'
                    });
                } else {
                    this.isSearch = false
                    // this.status = "none"
                    console.log('error')
                    this.$message({
                        message: '下载异常!!',
                        type: 'error'
                    });
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.necess_container {
    margin-bottom: 10px;
    height: 416px;
    // background-color: red;
}

.version {
    font-size: 12px !important;
    color: gray;
}

.necess_card {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding: 10px 30px;
    align-items: center;
    justify-content: flex-start;
    height: 80px;

    .necess_icon_url {
        width: 60px;
        height: 60px;
        margin-right: 8px;
    }

    .necess_soft_info {
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        .necess_soft_info_txt {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 264px;
        }
    }

    .necess_soft_info_down {
        position: absolute;
        right: 30px;
        top: 26px;
        color: #FFF;
        border-radius: 28px;
        background: #2049EE;
        color: #FFF;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        padding: 6px 10px;
        width: 58px;
        height: 16px;
        line-height: 16px;
        cursor: pointer;
    }
}

.necess_card:hover {
    background-color: #EAF4FF;
    // box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.5);
}
</style>