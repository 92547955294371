<template>
  <div class="home">
    <div class="header">
      <div class="header_info">
        <img src="../assets/longshine_logo_top.png" class="header_logo">
        <div class="header_info_right">
          <img src="../assets/search.png" class="search_icon">
          <div>
            <form class="search_form">
              <input v-model="searchText" @input="getSoftSearch" placeholder="在这里输入你要找的软件" autocomplete="off">
              <ul v-if="isSearch" class="search_list" :style="{ display: status }">
                <li v-for="item in filteredList" :key="item.id" @click="closeSearch(item.Name)">
                  {{ item.Name }}
                </li>
              </ul>
              <ul v-if="isNoData" class="search_list_no" :style="{ display: status }">
                <div class="data_box_no">
                  <img src="../assets/no_data.png" class="data_no">
                  <div class="data_no_txt">没有查询到哦</div>
                </div>
              </ul>
              <!-- <ul v-else></ul> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="notice-container">
      <div class="notice" :style="{ animationDuration: duration + 's' }">
        <p>{{ message }}</p>
      </div>
    </div> -->
    <div class="main">
      <div class="select">
        <div class="select_header">
          <div class="title">精选软件</div>
          <div class="select_change" @click="changePage">换一批</div>
        </div>
        <SelectList :list="softSelectList"></SelectList>
      </div>
      <div class="necess">
        <div class="necess_info one">
          <div class="necess_info_header one_header">
            <div class="necess_info_header_title">
              <img src="../assets/svg/st_type_left_0.svg">
              <div class="necess_info_title">IT支持</div>
              <img src="../assets/svg/st_type_right_0.svg">
            </div>
            <div class="necess_info_header_promt promt_one">
              90%好评高效工作
            </div>
          </div>
          <div class="necess_info_main">
            <NecessList :list="softNecessList"></NecessList>
          </div>
          <div class="necess_info_footer">
            <img src="../assets/svg/pre-page-gray.svg" v-show="isFirstNecess" @click="goPrePage">
            <img src="../assets/svg/pre-page.svg" v-show="!isFirstNecess" @click="goPrePage">
            <div class="necess_info_page">
              <div>{{ pageNecessIndex }}</div>
              /
              <div>{{ necessTotalPage }}</div>
            </div>
            <img src="../assets/svg/after-page.svg" v-show="!isLastNecess" @click="goAfterPage">
            <img src="../assets/svg/after-page-gray.svg" v-show="isLastNecess" @click="goAfterPage">
          </div>
        </div>
        <div class="necess_info two">
          <div class="necess_info_header two_header">
            <div class="necess_info_header_title">
              <img src="../assets/svg/st_type_left_1.svg">
              <div class="necess_info_title">办公必备</div>
              <img src="../assets/svg/st_type_right_1.svg">
            </div>
            <div class="necess_info_header_promt promt_two">
              90%好评高效工作
            </div>
          </div>
          <div class="necess_info_main">
            <SecoundList :list="softSecoundList"></SecoundList>
          </div>
          <div class="necess_info_footer">
            <img src="../assets/svg/pre-page-gray.svg" v-show="isFirstSecound" @click="goPrePage1">
            <img src="../assets/svg/pre-page.svg" v-show="!isFirstSecound" @click="goPrePage1">
            <div class="necess_info_page">
              <div>{{ pageSecoundIndex }}</div>
              /
              <div>{{ secoundTotalPage }}</div>
            </div>
            <img src="../assets/svg/after-page.svg" v-show="!isLastSecound" @click="goAfterPage1">
            <img src="../assets/svg/after-page-gray.svg" v-show="isLastSecound" @click="goAfterPage1">
          </div>
        </div>
        <div class="necess_info three">
          <div class="necess_info_header two_header">
            <div class="necess_info_header_title">
              <img src="../assets/svg/st_type_left_2.svg">
              <div class="necess_info_title">办公工具</div>
              <img src="../assets/svg/st_type_right_2.svg">
            </div>
            <div class="necess_info_header_promt promt_two">
              90%好评高效工作
            </div>
          </div>
          <div class="necess_info_main">
            <ThirdList :list="softThirdList"></ThirdList>
          </div>
          <div class="necess_info_footer">
            <img src="../assets/svg/pre-page-gray.svg" v-show="isFirstThird" @click="goPrePage2">
            <img src="../assets/svg/pre-page.svg" v-show="!isFirstThird" @click="goPrePage2">
            <div class="necess_info_page">
              <div>{{ pageThirdIndex }}</div>
              /
              <div>{{ thirdTotalPage }}</div>
            </div>
            <img src="../assets/svg/after-page.svg" v-show="!isLastThird" @click="goAfterPage2">
            <img src="../assets/svg/after-page-gray.svg" v-show="isLastThird" @click="goAfterPage2">
          </div>
        </div>
      </div>
      <div class="select">
        <div class="select_header">
          <!-- <div class="title">精选软件</div> -->
          <div class="title">热门分类</div>
          <!-- <div class="select_change" @click="changePage">换一批</div> -->
        </div>
        <div class="soft_type_list">
          <div v-for="(item, index) in softTypeList" :key="index" class="soft_types">
            <div class="soft_type">
              <div @click="getSoftType(item, index)" :class="{ 'selected': selectIndex === index }">{{ item }}</div>
            </div>
          </div>
        </div>
        <SoftClassList :list="currentList"></SoftClassList>
        <div class="select_footer" v-if="isMoreShow">
          <div class="more_button" @click="getMoreSoft()">
            加载
            <div>{{ moreInfo }}</div>
            <img src="../assets/down.png" class="more_button_icon">
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_info">
        <div class="footer_info_com">@2024-2024 朗新科技集团股份有限公司 版权所有. (版号XXXXXXXXXXXXXXX)</div>
        <div class="footer_info_content">如对网站内容或功能有任何建议请钉钉联系 流程与系统部-信息技术部-施友涵</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSoftType, GetSoftSelect, GetSoftList, GetSoftSearch, SoftDown } from "@/api/hello"
import NecessList from "@/components/NecessList.vue"
import SecoundList from "@/components/SecoundList.vue"
import ThirdList from "@/components/ThirdList.vue"
import SelectList from "@/components/SelectList.vue"
import SoftClassList from "@/components/SoftClassList.vue"
export default {
  components: {
    NecessList, SecoundList, ThirdList, SelectList, SoftClassList
  },
  data() {
    return {
      indexNum: "",
      softList: [],
      pageSelectIndex: 1,
      pageSelectSize: 8,
      pageNecessIndex: 1,
      pageNecessSize: 5,
      pageSecoundIndex: 1,
      pageSecoundSize: 5,
      pageThirdIndex: 1,
      pageThirdSize: 5,
      pageClassIndex: 1,
      pageClassSize: 15,
      softSelectList: [],
      softNecessList: [],
      softSecoundList: [],
      softThirdList: [],
      softClassList: [],
      totalPage: 0,
      necessTotalPage: 0,
      secoundTotalPage: 0,
      thirdTotalPage: 0,
      classTotalPage: 0,
      divWidth: 164,
      isLastNecess: false,
      isFirstNecess: true,
      isLastSecound: false,
      isFirstSecound: true,
      isLastThird: false,
      isFirstThird: true,
      softTypeList: ['全部', '工具', '办公', 'IT'],
      message: "🔈: 网站目前还在测试阶段，请忽略网站性能！因个人服务器带宽限制，下载速度暂且较慢。",
      duration: 30, // 公告滚动的时长（秒）
      selectIndex: 0,
      currentIndex: 2,
      currentList: [],
      moreInfo: "更多",
      isMoreShow: false,
      isSearch: false,
      isNoData: false,
      searchText: "",
      itemList: [],
      filteredList: [],
      status: "none"
    }
  },
  created() {
    this.getSelectList()
    this.getNecessList()
    this.getThirdList()
    this.getSecoundList()
    this.getSoftList()
  },
  // computed: {
  //   filteredList() {
  //     return this.itemList.filter(item =>
  //       item.Name.toUpperCase().includes(this.searchText.toUpperCase())
  //     );
  //   }
  // },
  methods: {
    closeSearch(softName) {
      this.status = "none"
      this.searchText = ""
      const formData = new FormData()
      formData.append("name", softName)
      SoftDown(formData).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          location.href = res.data.data.Url
          this.$message({
            message: '下载成功',
            type: 'success'
          });
        } else {
          this.isSearch = false
          // this.status = "none"
          console.log('error')
          this.$message({
            message: '下载异常!!',
            type: 'error'
          });
        }
      })
    },
    getSoftSearch() {
      if (this.searchText.length === 0) { this.status = "none" } else {
        this.status = "block"
        this.isNoData = false
      }
      if (this.searchText !== '') {
        const params = {
          "name": this.searchText
        }
        GetSoftSearch(params).then(res => {
          console.log(res.data)
          if (res.data.code === 2000) {
            this.itemList = res.data.data
            this.isSearch = true
            // this.status = "block"
            this.filteredList = this.itemList.slice(0, 6).filter(item =>
              item.Name.toUpperCase().includes(this.searchText.toUpperCase())
            )
            if (this.filteredList.length === 0 && this.searchText.length != 0) {
              this.isNoData = true
            } else {
              this.filteredList.slice(0, 6)
            }
          } else {
            this.isSearch = false
            // this.isNoData = true
            // this.status = "none"
            console.log('error')
          }
        })
      } else {
        this.itemList = []
        this.filteredList = []
        console.log("你什么都没输入")
        //   if (this.searchText !== ''){
        //   const params = {
        //   "name": this.searchText
        // }
        // GetSoftSearch(params).then(res => {
        //   console.log(res.data)
        //   if (res.data.code === 2000) {
        //     this.itemList = res.data.data
        //     this.isSearch = true
        //   } else {
        //     this.isSearch = false
        //     console.log('error')
        //   }
        // })
      }
    },
    getNecessList() {
      const params = {
        "page": this.pageNecessIndex,
        "pageSize": this.pageNecessSize,
        "softType": "IT"
      }
      GetSoftType(params).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          this.softNecessList = res.data.data.data
          this.necessTotalPage = Math.ceil(res.data.data.total / this.pageNecessSize)
          // console.log("一共多少页，" + this.necessTotalPage)
          if (this.necessTotalPage === 1) {
            this.isLastNecess = true
          }
        } else {
          console.log('error')
        }
      })
    },
    getSecoundList() {
      const params = {
        "page": this.pageSecoundIndex,
        "pageSize": this.pageSecoundSize,
        "softType": "办公"
      }
      GetSoftType(params).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          this.softSecoundList = res.data.data.data
          this.secoundTotalPage = Math.ceil(res.data.data.total / this.pageSecoundSize)
          // console.log("一共多少页，" + this.necessTotalPage)
          if (this.secoundTotalPage === 1) {
            this.isLastSecound = true
          }
        } else {
          console.log('error')
        }
      })
    },
    getThirdList() {
      const params = {
        "page": this.pageThirdIndex,
        "pageSize": this.pageThirdSize,
        "softType": "工具"
      }
      GetSoftType(params).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          this.softThirdList = res.data.data.data
          this.thirdTotalPage = Math.ceil(res.data.data.total / this.pageThirdSize)
          // console.log("一共多少页，" + this.necessTotalPage)
          if (this.thirdTotalPage === 1) {
            this.isLastThird = true
          }
        } else {
          console.log('error')
        }
      })
    },
    getSelectList() {
      const params = {
        "page": this.pageSelectIndex,
        "pageSize": this.pageSelectSize
      }
      GetSoftSelect(params).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          this.softSelectList = res.data.data.data
          this.totalPage = Math.ceil(res.data.data.total / this.pageSelectSize)
        } else {
          console.log('error')
        }
      });
    },
    getSoftList() {
      const params = {
        "page": this.pageClassIndex,
        "pageSize": this.pageClassSize
      }
      GetSoftList(params).then(res => {
        console.log(res.data)
        if (res.data.code === 200) {
          // this.softClassList = res.data.data.data
          this.currentList = [...res.data.data.data]
          this.classTotalPage = Math.ceil(res.data.data.total / this.pageClassSize)
          if (this.classTotalPage === 1 && this.classTotalPage === res.data.data.pageSize) {
            this.isMoreShow = false
          } else {
            this.isMoreShow = true
          }
        } else {
          console.log('error')
        }
      })
    },
    getSoftType(item, index) {
      if (item === '全部') {
        this.currentIndex = 1
        this.getSoftList()
        this.selectIndex = index
      } else {
        const params = {
          "page": this.pageClassIndex,
          "pageSize": this.pageClassSize,
          "softType": item
        }
        GetSoftType(params).then(res => {
          console.log(res.data)
          if (res.data.code === 200) {
            this.currentList = [...res.data.data.data]
            this.selectIndex = index
            this.classTotalPage = Math.ceil(res.data.data.total / this.pageClassSize)
            if (this.classTotalPage === 1 || this.classTotalPage === res.data.data.page) {
              this.isMoreShow = false
            } else {
              this.isMoreShow = true
            }
          } else {
            console.log('error')
          }
        })
      }
    },
    getMoreSoft() {
      // this.doSomethingAfterDelay()
      this.moreInfo = "中..."
      // 延时1秒执行的操作
      setTimeout(() => {
        if (this.selectIndex === 0) {
          const params = {
            "page": this.currentIndex++,
            "pageSize": this.pageClassSize
          }
          GetSoftList(params).then(res => {
            console.log(res.data)
            if (res.data.code === 200) {
              // this.softClassList = res.data.data.data
              this.currentList = [...this.currentList, ...res.data.data.data]
              this.classTotalPage = Math.ceil(res.data.data.total / this.pageClassSize)
              if (this.classTotalPage === 1 || this.classTotalPage === res.data.data.page) {
                this.isMoreShow = false
              } else {
                this.isMoreShow = true
              }
            } else {
              console.log('error')
            }
          })
          console.log(this.classTotalPage)
        } else {
          const params = {
            "page": this.currentIndex++,
            "pageSize": this.pageClassSize,
            "softType": this.softTypeList[this.selectIndex]
          }
          GetSoftType(params).then(res => {
            console.log(res.data)
            if (res.data.code === 200) {
              // this.currentList = res.data.data.data
              this.currentList = [...this.currentList, ...res.data.data.data]
              this.classTotalPage = Math.ceil(res.data.data.total / this.pageClassSize)
              if (this.classTotalPage === 1 || this.classTotalPage === res.data.data.page) {
                this.isMoreShow = false
              } else {
                this.isMoreShow = true
              }
              // this.selectIndex = index
              // this.necessTotalPage = Math.ceil(res.data.data.total / this.pageNecessSize)
              // console.log("一共多少页，" + this.necessTotalPage)
              // if (this.necessTotalPage === 1) {
              //   this.isLastNecess = true
              // }
            } else {
              console.log('error')
            }
          })
        }
        this.moreInfo = "更多"
        // 这里可以放置你想要延时1秒后执行的代码
      }, 500);

    },
    changePage() {
      if (this.pageSelectIndex < this.totalPage) {
        this.pageSelectIndex++
        this.getSelectList()
      } else {
        this.pageSelectIndex = 1
        this.getSelectList()
      }
    },
    goPrePage() {
      if (this.pageNecessIndex >= 2) {
        this.pageNecessIndex--
        this.isLastNecess = false
        this.getNecessList()
      }
      else {
        // this.isFirstNecess = true
        this.$notify.info({
          message: '这是第一页啦!',
          // type: 'warning',
          position: 'bottom-right',
          offset: 100
        });
      }
      if (this.pageNecessIndex === 1) {
        this.isFirstNecess = true
      } else {
        this.isFirstNecess = false
      }
    },
    goAfterPage() {
      if (this.pageNecessIndex < this.necessTotalPage) {
        this.pageNecessIndex++
        this.isFirstNecess = false
        this.getNecessList()
      }
      else {
        if (this.necessTotalPage === 1) {
          this.$notify.info({
            message: '这已经是最后一页啦!',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        } else {
          this.$notify.info({
            message: '这是最后一页啦!' + ' ' + '请看看别的呢~',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        }
      }
      if (this.pageNecessIndex === this.necessTotalPage) {
        this.isLastNecess = true
      } else {
        this.isLastNecess = false
      }
    },
    goPrePage1() {
      if (this.pageSecoundIndex >= 2) {
        this.pageSecoundIndex--
        this.isLastSecound = false
        this.getSecoundList()
      }
      else {
        // this.isFirstNecess = true
        this.$notify.info({
          message: '这是第一页啦!',
          // type: 'warning',
          position: 'bottom-right',
          offset: 100
        });
      }
      if (this.pageSecoundIndex === 1) {
        this.isFirstSecound = true
      } else {
        this.isFirstSecound = false
      }
    },
    goAfterPage1() {
      if (this.pageSecoundIndex < this.secoundTotalPage) {
        this.pageSecoundIndex++
        this.isFirstSecound = false
        this.getSecoundList()
      }
      else {
        if (this.secoundTotalPage === 1) {
          this.$notify.info({
            message: '这已经是最后一页啦!',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        } else {
          this.$notify.info({
            message: '这是最后一页啦!' + ' ' + '请看看别的呢~',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        }
      }
      if (this.pageSecoundIndex === this.secoundTotalPage) {
        this.isLastSecound = true
      } else {
        this.isLastSecound = false
      }
    },
    goPrePage2() {
      if (this.pageThirdIndex >= 2) {
        this.pageThirdIndex--
        this.isLastThird = false
        this.getThirdList()
      }
      else {
        // this.isFirstNecess = true
        this.$notify.info({
          message: '这是第一页啦!',
          // type: 'warning',
          position: 'bottom-right',
          offset: 100
        });
      }
      if (this.pageThirdIndex === 1) {
        this.isFirstThird = true
      } else {
        this.isFirstThird = false
      }
    },
    goAfterPage2() {
      if (this.pageThirdIndex < this.thirdTotalPage) {
        this.pageThirdIndex++
        this.isFirstThird = false
        this.getThirdList()
      }
      else {
        if (this.thirdTotalPage === 1) {
          this.$notify.info({
            message: '这已经是最后一页啦!',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        } else {
          this.$notify.info({
            message: '这是最后一页啦!' + ' ' + '请看看别的呢~',
            // type: 'warning',
            position: 'bottom-right',
            offset: 100
          });
        }
      }
      if (this.pageThirdIndex === this.thirdTotalPage) {
        this.isLastThird = true
      } else {
        this.isLastThird = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  background-color: #f7faff !important;
}

.header {
  display: flex;
  flex-direction: column;
  height: 100px;
  min-width: 1200px;
  justify-content: center;
  align-items: center;
  background-color: #13C2B4;

  .header_info {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .header_logo {
      height: 50px;
    }

    .header_info_right {
      padding: 5px 20px 5px 20px;
      position: relative;
      top: 100;
      right: 100;
      background-color: white;
      width: 300px;
      height: 40px;
      border-radius: 30px;
      display: flex;
      // justify-content: center;
      align-items: center
    }

    .search_icon {
      height: 30px;
      width: 30px;
      padding-right: 10px;
    }

    input {
      // background: linear-gradient(180deg, rgba(185, 239, 210, 0.08), rgba(216, 239, 236, 0.08));
      border: 0;
      box-sizing: border-box;
      color: #4d4d4d;
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      width: 260px;
      height: 40px;
      // position: relative;
    }

    input:focus {
      outline: none;
    }
  }
}

.search_form {
  position: relative;

  .search_list {
    // position: absolute;
    // z-index: 999;
    // background-color: #1271FF;
    list-style: none;
    background: #fff;
    border: 1px solid #f6faff;
    border-radius: 5px;
    box-shadow: 0 7px 12px 0 rgba(34, 176, 182, .1);
    left: 0;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 999;
    // width: 286px;

    li {
      font-family: Microsoft YaHei;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      gap: 10px;
      align-items: center;
      // color: #8f8f8f;
      color: black;
      display: block;
      padding: 10px 20px;
      // width: 100%;
    }

    li:hover {
      color: blue;
      background-color: #EAF4FF;
    }
  }

  .search_list_no {
    // position: absolute;
    // z-index: 999;
    // background-color: #1271FF;
    list-style: none;
    background: #fff;
    // border: 1px solid #f6faff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 7px 12px 0 rgba(12, 137, 142, 0.1);
    left: 0;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 999;

    .data_box_no {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 258px;
      height: 258px;
    }

    .data_no_txt {
      font-size: 18px;
      padding-top: 20px;
      color: #cdcdcd;
    }

    .data_no {
      width: 120px;
      height: 100px;
    }
  }
}


.title {
  font-size: 20px;
  font-weight: bolder;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7faff;
  // background-color: orchid;
}

.select {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 1140px;
  margin: 30px 0;
  border-radius: 8px;
  //background-color: red;
  background-color: #FFFFFF;

  .select_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1140px;
    height: 40px;
    // background-color: yellow;

    .select_change {
      font-size: 14px;
      color: blue;
      font-weight: bold;
    }
  }
}

.necess {
  display: flex;
  width: 1200px;
  height: 537px;
  // margin-bottom: 30px;

  .necess_info {
    width: 390px;
    background-color: white;
    margin-right: 12px;
    border-radius: 8px;
    // border: 1px solid rgba(0, 0, 0, 0.06);

    .necess_info_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 0px 20px;
      margin-bottom: 12px;

      .necess_info_header_title {
        display: flex;
      }

      .necess_info_title {
        font-size: 20px;
        font-family: "Microsoft YaHei UI";
        font-weight: 700;
      }

      .necess_info_header_promt {
        display: inline-flex;
        height: 18.116px;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 2px;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }

      .promt_one {
        background: rgba(255, 134, 23, 0.10);
      }

      .promt_two {
        background: rgba(18, 113, 255, 0.10);

      }
    }

    .necess_info_footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .necess_info_page {
        width: 62px;
        height: 31px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        color: rgba(0, 0, 0, 0.30);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin: 0 8px;
      }
    }
  }

  .necess_info:last-child {
    margin-right: 0px !important;
  }

  .one {
    border: 1px solid rgba(255, 154, 68, 0.30);
    background: linear-gradient(205deg, rgba(250, 144, 20, 0.10) 0%, rgba(255, 255, 255, 0.30) 48.44%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(180deg, #FFF 0%, #FFF 100%);

    .one_header {
      color: #FA9014;
    }
  }

  .two {
    border: 1px solid rgba(37, 121, 255, 0.30);
    background: linear-gradient(205deg, rgba(152, 198, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 48.44%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(180deg, #FFF 0%, #FFF 100%);

    .two_header {
      color: #1271FF;
    }
  }



  .three {
    border: 1px solid rgba(0, 179, 93, 0.30);
    background: linear-gradient(205deg, rgba(161, 255, 188, 0.30) 0%, rgba(255, 255, 255, 0.30) 48.44%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(180deg, #FFF 0%, #FFF 100%);

    .two_header {
      color: #06A95B;
    }
  }
}

.soft_type_list {
  padding: 10px 0px;
  display: flex;
  align-items: flex-start;

  .soft_type {
    display: flex;
    margin-right: 26px;
    color: #17171A;
    font-family: "PingFang SC";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
  }

  .selected {
    color: blue;
  }
}

.select_footer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  height: 30px;

  .more_button {
    cursor: pointer;
    box-sizing: border-box;
    width: 90px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.05);
    font-size: 14px;

    .more_button_icon {
      width: 14px;
      height: 14px;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  height: 100px;
  min-width: 1200px;
  justify-content: center;
  align-items: center;
  background-color: black;

  .footer_info {
    display: flex;
    flex-direction: column;
    width: 1200px;
    color: white;
    font-size: 12px;

    .footer_info_com {
      margin-bottom: 10px;
    }
  }
}

.notice-container {
  overflow: hidden;
  position: relative;
  height: 30px;
  width: 100%;
  background-color: rgb(217, 247, 117);
  /* 公告的高度 */
}

.notice {
  position: absolute;
  white-space: nowrap;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  /* 延迟公告开始滚动的时间 */
}

.notice>p {
  margin: 0;
  line-height: 30px;
  /* 公告的行高与容器高度相同 */
}

@keyframes scroll {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(-10vw);  }
}
</style>