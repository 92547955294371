<template>
    <div class="select_container">
        <div v-for="(item, index) in list" :key="index" class="select_cards">
            <div class="select_card" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave()">
                <div class="select_card_header">
                    <img :src="item.IconUrl" class="select_soft_logo" />
                    <!-- <div class="select_card_txt" :style="{ width: (indexNum !== index) ? 164 : 104 + 'px' }"> -->
                    <div class="select_card_txt" :style="selectNumber(index)">
                        <div class="select_soft_name name"> {{ item.Name }}</div>
                        <div class="select_soft_name version">Version {{ item.Version }}</div>
                    </div>
                    <div class="select_soft_down" v-show="indexNum === index" @click="downSelect(item.Name)">下载</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SoftDown } from "@/api/hello"
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            divWidth: 164,
            indexNum: "",
            // softName: ""
        }
    },
    methods: {
        mouseEnter(index) {
            this.indexNum = index
            this.divWidth = 84
            // this.softName = list[index].Name
        },
        mouseLeave() {
            this.indexNum = -1
            this.divWidth = 164
        },
        downSelect(softName) {
            const formData = new FormData()
            formData.append("name", softName)
            SoftDown(formData).then(res => {
                console.log(res.data)
                if (res.data.code === 200) {
                    location.href = res.data.data.Url
                    this.$message({
                        message: '下载成功',
                        type: 'success'
                    });
                } else {
                    this.isSearch = false
                    // this.status = "none"
                    console.log('error')
                    this.$message({
                        message: '下载异常!!',
                        type: 'error'
                    });
                }
            })
        },
        selectNumber(index) {
            if (this.indexNum === index) { return { width: this.divWidth + 'px' } } else {
                return { width: 164 + 'px' }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select_container {
    display: flex;
    flex-wrap: wrap;
    width: 1140px;
    height: 220px;
    // background-color: blue;

    .select_cards {
        position: relative;
        margin: 20px 0px 0px 12px;

        .select_card {
            width: 234px;
            height: 64px;
            // background-color: whitesmoke;
            padding: 12px 20px;
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            box-shadow: 1px 1px 2px 0 #f7faff;
            display: flex;
            align-items: center;


            .select_card_header {
                display: flex;

                .select_soft_logo {
                    width: 60px;
                    height: 60px;
                    padding-right: 12px;
                }

                .select_card_txt {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .select_soft_name {
                        font-size: 16px;
                        max-width: 164px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .version {
                        font-size: 12px !important;
                        color: gray;
                    }

                    .name {
                        padding-bottom: 5px;
                        // font-weight: bolder;
                    }
                }

                .select_soft_down {
                    position: absolute;
                    right: 20px;
                    top: 30px;
                    width: 60px;
                    height: 30px;
                    text-align: center;
                    background-color: #2049EE;
                    // padding: 4px 14px;
                    font-family: 'PingFang SC';
                    line-height: 30px;
                    font-size: 16px;
                    font-weight: bold;
                    color: white;
                    border-radius: 37px;
                }

            }
        }

        .select_card:hover {
            background-color: #EAF4FF;
            box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
        }
    }

    .select_cards:nth-child(4n-3) {
        margin-left: 0px !important;
    }
}
</style>